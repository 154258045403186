import { ProviderService } from 'shared/models/providerService.model';

export class MainPageCarouselHeaderThemeService extends ProviderService {
  static readonly serviceName = 'mainPageCarouselHeaderThemeService';
  private _isDarkTheme = ref<boolean>(true);


  public get isDarkTheme() : boolean {
    return this._isDarkTheme.value;
  }


  public setDarkTheme(value : boolean) {
    this._isDarkTheme.value = value;
  }
}
