<template>
  <div
    class="mm-main-page"
    :class="{
      logged: isSearchGroupAccess,
      'catalog-open': popupManager?.getIsPopupOpened?.()?.value,
    }"
  >
    <Header
      change-color
      :hide-search="!isSearchGroupAccess"
      is-transparent
      :links="mainHeaderLinks"
      @click-go-to-basket="Basket.goToBasketPage()"
    />
    <slot />
    <Footer />
    <GTM />
    <ModalsContainer />
    <CompareProductsDrawer />
  </div>
</template>

<script lang="ts" setup>
import GTM from 'shared/components/GTM.vue';
import Header from 'components/layouts/default/Header.vue';
import Footer from 'components/layouts/default/Footer.vue';
import { useAuthModal, useServerSsoAuth } from 'composables/useAuthHelper';
import { useSearchGroupAccess } from 'composables/useGroup';
import { useMainTitle } from 'composables/useMainTitle';
import { MainPageCarouselHeaderThemeService } from 'services/main-page/mainPageCarouselHeaderTheme.service';
import PopupManager from 'shared/services/popupManager.service';
import { IPopupManager } from 'shared/models/popupManager.model';
import { useCheckInternalUser } from 'shared/composables/useCheckInternalUser';
import { Basket } from 'services/basket-services/basket/basket.service';
import setAuthCookieHeaders from '../composables/setAuthCookieHeaders';
import { useRefreshBasketData } from 'composables/useRefreshBasketData';
import { useCompareProducts } from '../composables/useCompareProducts';
import CompareProductsDrawer from 'components/compare-products/CompareProductsDrawer.vue';
import { useMainHeaderLinks } from 'composables/useMainHeaderLinks';

setAuthCookieHeaders();
await useDomainAuth();

useMainTitle();

await useServerSsoAuth();
await useSetServerUserInfo();
useSetClientUserInfo();
useRefreshBasketData();
useAuthModal();
useCheckInternalUser();

const isSearchGroupAccess = useSearchGroupAccess();

const headerThemeService = new MainPageCarouselHeaderThemeService();
provide(MainPageCarouselHeaderThemeService.getServiceName(), headerThemeService);
const popupManager = process.client ? inject<IPopupManager>(PopupManager.getServiceName()) : undefined;

useCompareProducts();

const { mainHeaderLinks } = useMainHeaderLinks();
</script>
<style lang="scss" scoped>
@import 'styles/base/common/variables';

.mm-main-page {
  &.logged {
    :deep(.mm-header) {
      .header--light,
      .header--dark {
        border-bottom: none;
      }
    }

    &.catalog-open {
      :deep(.mm-header) {
        background: $white;
      }
    }
  }

  :deep(.mm-header) {
    background: transparent;
    position: relative;
    z-index: 6;
    & > .header--light,
    & > .header--dark {
      background: transparent;
      border-bottom: 1px solid $text-white-4;
    }

    & > .header--light {
      .header__links-item {
        font-size: 14px;
        line-height: 20px;
        border-right-color: $text-white-6;
        padding-right: 24px;

        a {
          color: $text-black;
        }
      }

      .login__btn {
        .mm-login-button-login {
          color: $text-black;
        }
      }
    }

    & > .header--dark {
      .header__menu--mobile .header__links-item a {
        color: $text-black;
      }

      .header__links-item {
        padding-right: 24px;

        a {
          color: $text-white-6;
        }
      }

      .login__btn {
        .mm-login-button-login,
        .mm-login-button-user {
          color: $text-white-6;
        }
      }
    }
  }
}

@media only screen and (max-width: 1279px) {
  .mm-main-page {
    &.logged {
      :deep(.mm-header) {
        margin-top: 0;
      }
    }
    :deep(.header) {
      margin-top: 0;
      border-radius: 0;
    }
  }
}
</style>
